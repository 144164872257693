<template>
  <div
    class="mt-25"
    :nav-class="isMobileView ? 'mb-50' : ''"
  >

    <div
      class="flex-fill d-flex-between mx-25 mx-md-1"
    >
      <div class="d-flex-center gap-1 text-warning">
        <feather-icon icon="AlertCircleIcon" />
        <span>{{ $t('flight.flightDetails') }}</span>
      </div>
      <div>
        <b-button
          :id="`button-retrieve-visa-${trip.id}`"
          variant="outline-primary"
          class="py-25 px-1 rounded-lg mt-25"
        >
          <div class="d-flex-center">
            <b-img
              class="text-info"
              blank-color="#3DA5DE"
              style="width: 20px;"
              :src="require('@icons/airport-security-icon.svg')"
            />
            <small>Kiểm tra VISA nối chuyến, nhập cảnh</small>
          </div>
        </b-button>
        <b-popover
          :target="`button-retrieve-visa-${trip.id}`"
          triggers="click"
          placement="top"
          class="rounded p-25"
        >
          <div class="d-flex-center">
            <div class="text-nowrap">
              Chọn quốc tịch:
            </div>
            <v-select
              v-model="nationalityChoose"
              class="mx-25"
              :options="countriesList"
              :reduce="(val) => val"
              :clearable="false"
              label="id"
              :placeholder="$t('placeholderSelect')"
              searchable
              style="width: 90px;"
              :filterable="false"
              @open="openGetCountries"
              @search="searchGetCountries"
            >
              <template #selected-option="data">
                {{ data.shortCode }}
              </template>
              <template #option="data">
                <span class="font-weight-bolder">({{ data.shortCode }})</span> {{ data.countryName }}
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
            <b-button
              variant="flat-primary"
              class="p-50 rounded-circle"
              @click.prevent.stop="handleRetrieveVisa(trip ,`button-retrieve-visa-${trip.id}`)"
            >
              <feather-icon
                size="18"
                icon="ArrowRightIcon"
              />
            </b-button>
          </div>
        </b-popover>
      </div>
    </div>

    <div
      v-for="(segment, indexSegment) in trip.segments"
      :key="indexSegment"
    >
      <div v-if="segment.flightLegInfoList && segment.flightLegInfoList.length > 0">
        <FlightDetail
          v-for="(seg, indexSeg) in segment.flightLegInfoList"
          :key="indexSeg"
          :class="indexSeg !== 0 ? 'border-top' : ''"
          :segment="seg"
          :trip="trip"
          :index-segment="indexSegment"
          :operating="segment.operating"
          :airline="segment.airline"
          :flight-number="segment.flightNumber"
        />
      </div>

      <FlightDetail
        v-else
        :segment="segment"
        :trip="trip"
        :index-segment="indexSegment"
        :operating="segment.operating"
        :airline="segment.airline"
        :flight-number="segment.flightNumber"
      />

      <!-- NOTE: bỏ theo y/c a Công (dup hiển thị trong FlightDetail) -->
      <!-- <div class="d-flex-center justify-content-start gap-1 mx-1 my-50">
        <span>{{ trip.fareOptions.groupClass.split(' | ')[indexSegment] }}</span>
        <span>
          ( {{ trip.fareOptions.bookingClass.split(' | ')[indexSegment] }}
          <small
            style="color: #E2365D !important;"
            class="h6"
          >
            {{ trip.fareOptions.fareBasisCode.split(' | ')[indexSegment] }}
          </small> )
        </span>
      </div> -->

      <!-- SECTION Giữa chặng -->
      <div v-if="indexSegment !== trip.segments.length - 1">
        <div v-if="segment.isNextAirport">
          <b-alert
            show
            variant="warning"
            class="py-50 px-1 shadow-sm d-flex align-items-center"
          >
            <feather-icon
              icon="AlertTriangleIcon"
              size="21"
              class="text-warning mr-1"
            />
            <b>{{ $t('flight.alertNextAirport') }}</b>
          </b-alert>
        </div>
        <div v-if="segment.isNextDay">
          <b-alert
            show
            variant="warning"
            class="py-50 px-1 shadow-sm d-flex align-items-center"
          >
            <feather-icon
              icon="AlertTriangleIcon"
              size="21"
              class="text-warning mr-1"
            />
            <b>{{ $t('flight.alertNextDay') }}</b>
          </b-alert>
        </div>
        <div v-if="segment.transitTime">
          <b-alert
            show
            variant="primary"
            class="py-50 px-1 shadow-sm d-flex align-items-center"
          >
            <feather-icon
              icon="InfoIcon"
              size="21"
              class="text-primary mr-1"
            />
            <b>{{ $t('ticket.flightsInfo.transitTime') }}: {{ segment.transitTime }}</b>
          </b-alert>
        </div>
        <b-alert
          show
          variant="primary"
          class="py-50 px-1 shadow-sm d-flex align-items-center"
        >
          <feather-icon
            icon="InfoIcon"
            size="21"
            class="text-primary mr-1"
          />
          <b>{{ $t('flight.transitFlightInfo') }}</b>
        </b-alert>
      </div>
    </div>

    <div class="px-1 pb-50">
      <FareRulesDetail
        :fare-item="trip.fareOptions"
        :trip="trip"
      />
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BImg,
  VBToggle,
  BAlert,
  VBTooltip,
  BPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'

import {
  checkTransitAlert,
} from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BImg,
    BButton,
    BAlert,
    BPopover,
    vSelect,
    FlightDetail: () => import('@/components/flight-detail/FlightDetail.vue'),
    FareRulesDetail: () => import('../FareRulesDetail.vue'),
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },

  props: {
    trip: {
      type: Object,
      default: () => { },
    },
  },

  created() {
    if (this.trip.segments.length > 0) {
      this.checkAlertSegments(this.trip)
    }
  },
  methods: {
    checkAlertSegments(data) {
      for (let i = 0; i < data.segments.length - 1; i += 1) {
        const segment = data.segments[i]
        const nextSegment = data.segments[i + 1]
        const { isNextAirport, isNextDay, transitTime } = checkTransitAlert(segment.arrival, nextSegment.departure)
        Object.assign(segment, {
          isNextAirport, isNextDay, transitTime,
        })
      }
    },
  },
  setup() {
    const {
      retrieveVisa,
      countriesList,
      openGetCountries,
      searchGetCountries,
    } = useBookingHandle()

    const nationalityChoose = ref({
      id: 192,
      countryName: 'Vietnam',
      shortCode: 'VN',
      longCode: 'VNM',
    })
    function handleRetrieveVisa(trip, idPopover) {
      this.$root.$emit('bv::hide::popover', idPopover)
      this.$bvModal.show('modal-api-loading')

      const segmentArray = trip.segments.map(segment => segment.arrival.IATACode)
      const arrivals = [segmentArray.pop()]
      if (!nationalityChoose.value || (segmentArray.length === 0 && arrivals.length === 0)) return
      const payload = {
        source: '1G',
        nationality: nationalityChoose.value?.shortCode,
        arrivals,
        transits: segmentArray,
      }
      retrieveVisa(payload)
        .then(res => {
          this.$bvModal.hide('modal-api-loading')

          const titleMsg = () => {
            const messageVNode = res.join('\n').split('\n').map(item => this.$createElement('div', { domProps: { innerHTML: item } }))
            const contentVNode = this.$createElement('div', { class: ['card py-1 px-2 mb-0 text-wrap'], style: 'font-size: 16px; line-height: 24px; background-color: rgb(255, 255, 192); color: rgb(0, 0, 0); font-family: Calibri, sans-serif;' }, [messageVNode])
            return contentVNode
          }
          this.$bvModal
            .msgBoxOk(titleMsg(), {
              title: 'Kiểm tra VISA',
              size: 'lg',
              okVariant: 'secondary',
              okTitle: this.$t('modal.close'),
              hideFooter: true,
              centered: true,
              hideHeaderClose: false,
            })
        })
        .finally(() => { this.$bvModal.hide('modal-api-loading') })
    }

    return {
      handleRetrieveVisa,
      nationalityChoose,
      countriesList,
      openGetCountries,
      searchGetCountries,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
