var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-25",
    attrs: {
      "nav-class": _vm.isMobileView ? 'mb-50' : ''
    }
  }, [_c('div', {
    staticClass: "flex-fill d-flex-between mx-25 mx-md-1"
  }, [_c('div', {
    staticClass: "d-flex-center gap-1 text-warning"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "AlertCircleIcon"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('flight.flightDetails')))])], 1), _c('div', [_c('b-button', {
    staticClass: "py-25 px-1 rounded-lg mt-25",
    attrs: {
      "id": "button-retrieve-visa-".concat(_vm.trip.id),
      "variant": "outline-primary"
    }
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "text-info",
    staticStyle: {
      "width": "20px"
    },
    attrs: {
      "blank-color": "#3DA5DE",
      "src": require('@icons/airport-security-icon.svg')
    }
  }), _c('small', [_vm._v("Kiểm tra VISA nối chuyến, nhập cảnh")])], 1)]), _c('b-popover', {
    staticClass: "rounded p-25",
    attrs: {
      "target": "button-retrieve-visa-".concat(_vm.trip.id),
      "triggers": "click",
      "placement": "top"
    }
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('div', {
    staticClass: "text-nowrap"
  }, [_vm._v(" Chọn quốc tịch: ")]), _c('v-select', {
    staticClass: "mx-25",
    staticStyle: {
      "width": "90px"
    },
    attrs: {
      "options": _vm.countriesList,
      "reduce": function reduce(val) {
        return val;
      },
      "clearable": false,
      "label": "id",
      "placeholder": _vm.$t('placeholderSelect'),
      "searchable": "",
      "filterable": false
    },
    on: {
      "open": _vm.openGetCountries,
      "search": _vm.searchGetCountries
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.shortCode) + " ")];
      }
    }, {
      key: "option",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v("(" + _vm._s(data.shortCode) + ")")]), _vm._v(" " + _vm._s(data.countryName) + " ")];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.nationalityChoose,
      callback: function callback($$v) {
        _vm.nationalityChoose = $$v;
      },
      expression: "nationalityChoose"
    }
  }), _c('b-button', {
    staticClass: "p-50 rounded-circle",
    attrs: {
      "variant": "flat-primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.handleRetrieveVisa(_vm.trip, "button-retrieve-visa-".concat(_vm.trip.id));
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "18",
      "icon": "ArrowRightIcon"
    }
  })], 1)], 1)])], 1)]), _vm._l(_vm.trip.segments, function (segment, indexSegment) {
    return _c('div', {
      key: indexSegment
    }, [segment.flightLegInfoList && segment.flightLegInfoList.length > 0 ? _c('div', _vm._l(segment.flightLegInfoList, function (seg, indexSeg) {
      return _c('FlightDetail', {
        key: indexSeg,
        class: indexSeg !== 0 ? 'border-top' : '',
        attrs: {
          "segment": seg,
          "trip": _vm.trip,
          "index-segment": indexSegment,
          "operating": segment.operating,
          "airline": segment.airline,
          "flight-number": segment.flightNumber
        }
      });
    }), 1) : _c('FlightDetail', {
      attrs: {
        "segment": segment,
        "trip": _vm.trip,
        "index-segment": indexSegment,
        "operating": segment.operating,
        "airline": segment.airline,
        "flight-number": segment.flightNumber
      }
    }), indexSegment !== _vm.trip.segments.length - 1 ? _c('div', [segment.isNextAirport ? _c('div', [_c('b-alert', {
      staticClass: "py-50 px-1 shadow-sm d-flex align-items-center",
      attrs: {
        "show": "",
        "variant": "warning"
      }
    }, [_c('feather-icon', {
      staticClass: "text-warning mr-1",
      attrs: {
        "icon": "AlertTriangleIcon",
        "size": "21"
      }
    }), _c('b', [_vm._v(_vm._s(_vm.$t('flight.alertNextAirport')))])], 1)], 1) : _vm._e(), segment.isNextDay ? _c('div', [_c('b-alert', {
      staticClass: "py-50 px-1 shadow-sm d-flex align-items-center",
      attrs: {
        "show": "",
        "variant": "warning"
      }
    }, [_c('feather-icon', {
      staticClass: "text-warning mr-1",
      attrs: {
        "icon": "AlertTriangleIcon",
        "size": "21"
      }
    }), _c('b', [_vm._v(_vm._s(_vm.$t('flight.alertNextDay')))])], 1)], 1) : _vm._e(), segment.transitTime ? _c('div', [_c('b-alert', {
      staticClass: "py-50 px-1 shadow-sm d-flex align-items-center",
      attrs: {
        "show": "",
        "variant": "primary"
      }
    }, [_c('feather-icon', {
      staticClass: "text-primary mr-1",
      attrs: {
        "icon": "InfoIcon",
        "size": "21"
      }
    }), _c('b', [_vm._v(_vm._s(_vm.$t('ticket.flightsInfo.transitTime')) + ": " + _vm._s(segment.transitTime))])], 1)], 1) : _vm._e(), _c('b-alert', {
      staticClass: "py-50 px-1 shadow-sm d-flex align-items-center",
      attrs: {
        "show": "",
        "variant": "primary"
      }
    }, [_c('feather-icon', {
      staticClass: "text-primary mr-1",
      attrs: {
        "icon": "InfoIcon",
        "size": "21"
      }
    }), _c('b', [_vm._v(_vm._s(_vm.$t('flight.transitFlightInfo')))])], 1)], 1) : _vm._e()], 1);
  }), _c('div', {
    staticClass: "px-1 pb-50"
  }, [_c('FareRulesDetail', {
    attrs: {
      "fare-item": _vm.trip.fareOptions,
      "trip": _vm.trip
    }
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }